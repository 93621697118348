import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import PageLayout from "../components/layout/page/page"

const PrivacyPage = ({ data }) => (
  <Layout>
    <SEO title="Privacy Policy" keywords="privacy, policy" />
    <PageLayout name="Privacy Policy" />
    <section style={{ marginBottom: "70px" }}>
      <div className="container">
        <div className="row" style={{ padding: "0 15px" }}>
          <div className="privacy-content">
            <div className="">
              {/* <h1 className="sans">Our Privacy Policy</h1> */}
              <div className="">
                <p className="sans p-update">
                  Last updated: {data.site.siteMetadata.date}
                </p>
              </div>
            </div>
            <p className="sans p-details">
              This Privacy Policy describes Our policies and procedures on the
              collection, use and disclosure of Your information when You use
              the Service and tells You about Your privacy rights and how the
              law protects You. <br /> <br /> We use Your Personal data to
              provide and improve the Service. By using the Service, You agree
              to the collection and use of information in accordance with this
              Privacy Policy. Interpretation and Definitions
            </p>
            <div className="p-content">
              <div className="d-flex justify-content-left">
                <h3 className="sans">Interpretation</h3>
              </div>
              <p>
                The words of which the initial letter is capitalized have
                meanings defined under the following conditions. The following
                definitions shall have the same meaning regardless of whether
                they appear in singular or in plural.
              </p>
              <div className="d-flex justify-content-left">
                <h3 className="sans">Definitions</h3>
              </div>
              <p>
                For the purposes of this Privacy Policy: <br /> <br /> * You
                means the individual accessing or using the Service, or the
                company, or other legal entity on behalf of which such
                individual is accessing or using the Service, as applicable.{" "}
                <br /> <br /> * Company (referred to as either "the Company",
                "We", "Us" or "Our" in this Agreement) refers to
                <b> {data.site.siteMetadata.domain} </b>, 11319 Orange Dr.
                Whittier, CA 90606.
                <br /> <br /> * Affiliate means an entity that controls, is
                controlled by or is under common control with a party, where
                "control" means ownership of 50% or more of the shares, equity
                interest or other securities entitled to vote for election of
                directors or other managing authority.
                <br /> <br /> * Account means a unique account created for You
                to access our Service or parts of our Service.
                <br /> <br /> * Website refers to{" "}
                <b> {data.site.siteMetadata.name} </b> & Catering, accessible
                from
                <Link to="/"> {data.site.siteMetadata.siteUrl}</Link>. <br />
                <br />
                * Service refers to the Website.
                <br /> <br /> * Country refers to: California, United States
                <br /> <br /> * Service Provider means any natural or legal
                person who processes the data on behalf of the Company. It
                refers to third-party companies or individuals employed by the
                Company to facilitate the Service, to provide the Service on
                behalf of the Company, to perform services related to the
                Service or to assist the Company in analyzing how the Service is
                used.
                <br /> <br /> * Third-party Social Media Service refers to any
                website or any social network website through which a User can
                log in or create an account to use the Service.
                <br /> <br /> * Personal Data is any information that relates to
                an identified or identifiable individual. For the purposes of
                the CCPA, Personal Data means any information that identifies,
                relates to, describes or is capable of being associated with, or
                could reasonably be linked, directly or indirectly, with You.{" "}
                <br />
                <br /> * Cookies are small files that are placed on Your
                computer, mobile device or any other device by a website,
                containing the details of Your browsing history on that website
                among its many uses. <br />
                <br /> * Device means any device that can access the Service
                such as a computer, a cellphone or a digital tablet.
                <br /> <br /> * Usage Data refers to data collected
                automatically, either generated by the use of the Service or
                from the Service infrastructure itself (for example, the
                duration of a page visit).
                <br /> <br /> * Do Not Track (DNT) is a concept that has been
                promoted by US regulatory authorities, in particular the U.S.
                Federal Trade Commission (FTC), for the Internet industry to
                develop and implement a mechanism for allowing internet users to
                control the tracking of their online activities across websites.
                <br /> <br /> * Business , for the purpose of the CCPA
                (California Consumer Privacy Act), refers to the Company as the
                legal entity that collects Consumers' personal information and
                determines the purposes and means of the processing of
                Consumers' personal information, or on behalf of which such
                information is collected and that alone, or jointly with others,
                determines the purposes and means of the processing of
                consumers' personal information, that does business in the State
                of California.
                <br /> <br /> * Consumer , for the purpose of the CCPA
                (California Consumer Privacy Act), means a natural person who is
                a California resident. A resident, as defined in the law,
                includes (1) every individual who is in the USA for other than a
                temporary or transitory purpose, and (2) every individual who is
                domiciled in the USA who is outside the USA for a temporary or
                transitory purpose.
                <br /> <br /> * Sale , for the purpose of the CCPA (California
                Consumer Privacy Act), means selling, renting, releasing,
                disclosing, disseminating, making available, transferring, or
                otherwise communicating orally, in writing, or by electronic or
                other means, a Consumer's Personal information to another
                business or a third party for monetary or other valuable
                consideration.
              </p>
              <div className="d-flex justify-content-left">
                <h3 className="sans">
                  Collecting and Using Your Personal Data
                </h3>
              </div>
              <div className="d-flex justify-content-left">
                <h6 className="sans">Types of Data Collected:</h6>
              </div>
              <div className="d-flex justify-content-left">
                <h6 className="sans">Personal Data</h6>
              </div>
              <p>
                While using Our Service, We may ask You to provide Us with
                certain personally identifiable information that can be used to
                contact or identify You. Personally identifiable information may
                include, but is not limited to: <br /> <br /> * Email address{" "}
                <br />
                <br /> * First name and last name <br />
                <br /> * Phone number <br />
                <br /> * Address, State, Province, ZIP/Postal code, City <br />
                <br /> * Usage Data
              </p>
              <div className="d-flex justify-content-left">
                <h6 className="sans">Usage Data</h6>
              </div>
              <p>
                Usage Data is collected automatically when using the Service.{" "}
                <br /> <br /> Usage Data may include information such as Your
                Device's Internet Protocol address (e.g. IP address), browser
                type, browser version, the pages of our Service that You visit,
                the time and date of Your visit, the time spent on those pages,
                unique device identifiers and other diagnostic data. <br />
                <br /> When You access the Service by or through a mobile
                device, We may collect certain information automatically,
                including, but not limited to, the type of mobile device You
                use, Your mobile device unique ID, the IP address of Your mobile
                device, Your mobile operating system, the type of mobile
                Internet browser You use, unique device identifiers and other
                diagnostic data. <br />
                <br /> We may also collect information that Your browser sends
                whenever You visit our Service or when You access the Service by
                or through a mobile device.
              </p>
              <div className="d-flex justify-content-left">
                <h6 className="sans">Tracking Technologies and Cookies</h6>
              </div>
              <p>
                We use Cookies and similar tracking technologies to track the
                activity on Our Service and store certain information. Tracking
                technologies used are beacons, tags, and scripts to collect and
                track information and to improve and analyze Our Service. You
                can instruct Your browser to refuse all Cookies or to indicate
                when a Cookie is being sent. However, if You do not accept
                Cookies, You may not be able to use some parts of our Service.
                Cookies can be "Persistent" or "Session" Cookies. Persistent
                Cookies remain on your personal computer or mobile device when
                You go offline, while Session Cookies are deleted as soon as You
                close your web browser. Learn more about cookies: [All About
                Cookies]
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.termsfeed.com/blog/cookies/"
                >
                  (Link).
                </a>
                We use both session and persistent Cookies for the purposes set
                out below: <br />
                <br /> * Necessary / Essential Cookies <br />
                <br /> Type: Session Cookies <br />
                <br />
                Administered by: Us <br />
                <br /> Purpose: These Cookies are essential to provide You with
                services available through the Website and to enable You to use
                some of its features. They help to authenticate users and
                prevent fraudulent use of user accounts. Without these Cookies,
                the services that You have asked for cannot be provided, and We
                only use these Cookies to provide You with those services.{" "}
                <br />
                <br />
                * Cookies Policy / Notice Acceptance Cookies <br />
                <br /> Type: Persistent Cookies <br />
                <br /> Administered by: Us <br /> <br /> Purpose: These Cookies
                identify if users have accepted the use of cookies on the
                Website. <br />
                <br /> * Functionality Cookies Type: Persistent Cookies <br />
                <br /> Administered by: Us <br />
                <br />
                Purpose: These Cookies allow us to remember choices You make
                when You use the Website, such as remembering your login details
                or language preference. The purpose of these Cookies is to
                provide You with a more personal experience and to avoid You
                having to re-enter your preferences every time You use the
                Website. <br />
                <br /> * Tracking and Performance <br />
                <br /> Cookies Type: Persistent Cookies
                <br />
                <br /> Administered by: Third-Parties <br />
                <br /> Purpose: These Cookies are used to track information
                about traffic to the Website and how users use the Website. The
                information gathered via these Cookies may directly or
                indirectly identify you as an individual visitor. This is
                because the information collected is typically linked to a
                pseudonymous identifier associated with the device you use to
                access the Website. We may also use these Cookies to test new
                advertisements, pages, features or new functionality of the
                Website to see how our users react to them. <br />
                <br /> For more information about the cookies we use and your
                choices regarding cookies, please visit our Cookies Policy or
                the Cookies section of our Privacy Policy.
              </p>
              <div className="d-flex justify-content-left">
                <h6 className="sans">Use of Your Personal Data</h6>
              </div>
              <p>
                The Company may use Personal Data for the following purposes:{" "}
                <br />
                <br /> * To provide and maintain our Service , including to
                monitor the usage of our Service. <br />
                <br /> * To manage Your Account: to manage Your registration as
                a user of the Service. The Personal Data You provide can give
                You access to different functionalities of the Service that are
                available to You as a registered user. <br />
                <br /> * For the performance of a contract: the development,
                compliance and undertaking of the purchase contract for the
                products, items or services You have purchased or of any other
                contract with Us through the Service. <br />
                <br /> * To contact You: To contact You by email, telephone
                calls, SMS, or other equivalent forms of electronic
                communication, such as a mobile application's push notifications
                regarding updates or informative communications related to the
                functionalities, products or contracted services, including the
                security updates, when necessary or reasonable for their
                implementation. <br />
                <br /> * To provide You with news, special offers and general
                information about other goods, services and events which we
                offer that are similar to those that you have already purchased
                or enquired about unless You have opted not to receive such
                information. <br />
                <br /> * To manage Your requests: To attend and manage Your
                requests to Us. We may share your personal information in the
                following situations: <br />
                <br /> * With Service Providers: <br />
                <br /> We may share Your personal information with Service
                Providers to monitor and analyze the use of our Service, to
                advertise on third party websites to You after You visited our
                Service, for payment processing, to contact You. <br />
                <br /> * For Business transfers: We may share or transfer Your
                personal information in connection with, or during negotiations
                of, any merger, sale of Company assets, financing, or
                acquisition of all or a portion of our business to another
                company. <br />
                <br /> * With Affiliates: We may share Your information with Our
                affiliates, in which case we will require those affiliates to
                honor this Privacy Policy. Affiliates include Our parent company
                and any other subsidiaries, joint venture partners or other
                companies that We control or that are under common control with
                Us. <br />
                <br /> * With Business partners: We may share Your information
                with Our business partners to offer You certain products,
                services or promotions. <br />
                <br /> * With other users: when You share personal information
                or otherwise interact in the public areas with other users, such
                information may be viewed by all users and may be publicly
                distributed outside. If You interact with other users or
                register through a Third-Party Social Media Service, Your
                contacts on the Third-Party Social Media Service may see Your
                name, profile, pictures and description of Your activity.
                Similarly, other users will be able to view descriptions of Your
                activity, communicate with You and view Your profile.
              </p>
              <div className="d-flex justify-content-left">
                <h6 className="sans">Retention of Your Personal Data</h6>
              </div>
              <p>
                The Company will retain Your Personal Data only for as long as
                is necessary for the purposes set out in this Privacy Policy. We
                will retain and use Your Personal Data to the extent necessary
                to comply with our legal obligations (for example, if we are
                required to retain your data to comply with applicable laws),
                resolve disputes, and enforce our legal agreements and policies.{" "}
                <br />
                <br /> The Company will also retain Usage Data for internal
                analysis purposes. Usage Data is generally retained for a
                shorter period of time, except when this data is used to
                strengthen the security or to improve the functionality of Our
                Service, or We are legally obligated to retain this data for
                longer time periods.
              </p>
              <div className="d-flex justify-content-left">
                <h6 className="sans">Transfer of Your Personal Data</h6>
              </div>
              <p>
                Your information, including Personal Data, is processed at the
                Company's operating offices and in any other places where the
                parties involved in the processing are located. It means that
                this information may be transferred to — and maintained on —
                computers located outside of Your state, province, country or
                other governmental jurisdiction where the data protection laws
                may differ than those from Your jurisdiction. <br />
                <br />
                Your consent to this Privacy Policy followed by Your submission
                of such information represents Your agreement to that transfer.{" "}
                <br />
                <br /> The Company will take all steps reasonably necessary to
                ensure that Your data is treated securely and in accordance with
                this Privacy Policy and no transfer of Your Personal Data will
                take place to an organization or a country unless there are
                adequate controls in place including the security of Your data
                and other personal information.
              </p>
              <div className="d-flex justify-content-left">
                <h6 className="sans">Disclosure of Your Personal Data</h6>
              </div>
              <p>
                {" "}
                <b>Business Transactions</b>
                <br />
                If the Company is involved in a merger, acquisition or asset
                sale, Your Personal Data may be transferred. We will provide
                notice before Your Personal Data is transferred and becomes
                subject to a different Privacy Policy. <br /> <br />{" "}
                <b>Law enforcement</b>
                <br />
                Under certain circumstances, the Company may be required to
                disclose Your Personal Data if required to do so by law or in
                response to valid requests by public authorities (e.g. a court
                or a government agency). <br />
                <br /> <b>Other legal requirements</b>
                <br /> The Company may disclose Your Personal Data in the good
                faith belief that such action is necessary to: <br />
                <br /> * Comply with a legal obligation <br /> <br />* Protect
                and defend the rights or property of the Company <br /> <br />*
                Prevent or investigate possible wrongdoing in connection with
                the Service <br /> <br />* Protect the personal safety of Users
                of the Service or the public <br /> <br />* Protect against
                legal liability
              </p>
              <div className="d-flex justify-content-left">
                <h6 className="sans">Security of Your Personal Data</h6>
              </div>
              <p>
                The security of Your Personal Data is important to Us, but
                remember that no method of transmission over the Internet, or
                method of electronic storage is 100% secure. While We strive to
                use commercially acceptable means to protect Your Personal Data,
                We cannot guarantee its absolute security.
              </p>
              <div className="d-flex justify-content-left">
                <h3 className="sans">
                  Detailed Information on the Processing of Your Personal Data
                </h3>
              </div>
              <p>
                Service Providers have access to Your Personal Data only to
                perform their tasks on Our behalf and are obligated not to
                disclose or use it for any other purpose.
              </p>
              <div className="d-flex justify-content-left">
                <h6 className="sans">Analytics</h6>
              </div>
              <p>
                We may use third-party Service providers to monitor and analyze
                the use of our Service. <br />
                <br /> * Google Analytics <br />
                <br /> Google Analytics is a web analytics service offered by
                Google that tracks and reports website traffic. Google uses the
                data collected to track and monitor the use of our Service. This
                data is shared with other Google services. Google may use the
                collected data to contextualize and personalize the ads of its
                own advertising network. You can opt-out of having made your
                activity on the Service available to Google Analytics by
                installing the Google Analytics opt-out browser add- on. The
                add-on prevents the Google Analytics JavaScript (ga.js,
                analytics.js and dc.js) from sharing information with Google
                Analytics about visits activity. For more information on the
                privacy practices of Google, please visit the Google Privacy &
                Terms web page:{" "}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://policies.google.com/privacy"
                >
                  https://policies.google.com/privacy
                </a>{" "}
                <br /> <br />* Facebook Tracking <br /> <br /> Pixel Their
                Privacy Policy can be viewed at{" "}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.facebook.com/"
                >
                  https://www.facebook.com/
                </a>
              </p>
              <div className="d-flex justify-content-left">
                <h6 className="sans">Email Marketing</h6>
              </div>
              <p>
                We may use Your Personal Data to contact You with newsletters,
                marketing or promotional materials and other information that
                may be of interest to You. You may opt-out of receiving any, or
                all, of these communications from Us by following the
                unsubscribe link or instructions provided in any email We send
                or by contacting Us. <br />
                <br /> We may use Email Marketing Service Providers to manage
                and send emails to You. <br /> <br /> * Mailchimp <br />
                <br /> Mailchimp is an email marketing sending service provided
                by The Rocket Science Group LLC. For more information on the
                privacy practices of Mailchimp, please visit their Privacy
                policy:{" "}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://mailchimp.com/legal/privacy/"
                >
                  https://mailchimp.com/legal/privacy/
                </a>{" "}
                <br />
                <br />
                * AWeber <br />
                <br /> AWeber is an email marketing sending service provided by
                AWeber Communications. For more information on the privacy
                practices of AWeber, please visit their Privacy policy:{" "}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.aweber.com/privacy.htm"
                >
                  https://www.aweber.com/privacy.htm
                </a>{" "}
                <br />
                <br />
                * GetResponse <br />
                <br /> GetResponse is an email marketing sending service
                provided by GetResponse. For more information on the privacy
                practices of GetResponse, please visit their Privacy policy:{" "}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.getresponse.com/legal/privacy.html"
                >
                  Link
                </a>{" "}
                <br />
                <br />
                * SendFox.com <br />
                <br /> Their Privacy Policy can be viewed at{" "}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://sendfox.com/tos"
                >
                  https://sendfox.com/tos
                </a>
              </p>
              <div className="d-flex justify-content-left">
                <h6 className="sans">Payments</h6>
              </div>
              <p>
                We may provide paid products and/or services within the Service.
                In that case, we may use third-party services for payment
                processing (e.g. payment processors). We will not store or
                collect Your payment card details. That information is provided
                directly to Our third-party payment processors whose use of Your
                personal information is governed by their Privacy Policy. These
                payment processors adhere to the standards set by PCI-DSS as
                managed by the PCI Security Standards Council, which is a joint
                effort of brands like Visa, Mastercard, American Express and
                Discover. PCI-DSS requirements help ensure the secure handling
                of payment information. <br />
                <br /> * Stripe <br />
                <br /> Their Privacy Policy can be viewed at{" "}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://stripe.com/us/privacy"
                >
                  https://stripe.com/us/privacy
                </a>
              </p>

              <div className="d-flex justify-content-left">
                <h6 className="sans">Behavioral Remarketing</h6>
              </div>
              <p>
                The Company uses remarketing services to advertise on third
                party websites to You after You visited our Service. We and Our
                third-party vendors use cookies to inform, optimize and serve
                ads based on Your past visits to our Service. <br />
                <br /> * Google Ads <br />
                <br /> (AdWords) Google Ads (AdWords) remarketing service is
                provided by Google Inc. <br />
                <br /> You can opt-out of Google Analytics for Display
                Advertising and customise the Google Display Network ads by
                visiting the Google Ads Settings page:
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="http://www.google.com/settings/ads"
                >
                  Google Ads Settings Link.
                </a>{" "}
                <br />
                <br />
                Google also recommends installing the Google Analytics Opt-out
                Browser Add-on -{" "}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://tools.google.com/dlpage/gaoptout"
                >
                  Link.
                </a>{" "}
                \- for your web browser. Google Analytics Opt-out Browser Add-on
                provides visitors with the ability to prevent their data from
                being collected and used by Google Analytics. <br />
                <br /> For more information on the privacy practices of Google,
                please visit the Google Privacy & Terms web page:{" "}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://policies.google.com/privacy"
                >
                  https://policies.google.com/privacy
                </a>{" "}
                <br /> <br />
                * Bing Ads Remarketing <br />
                <br /> Bing Ads remarketing service is provided by Microsoft
                Inc. <br />
                <br /> You can opt-out of Bing Ads interest-based ads by
                following their instructions:{" "}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://advertise.bingads.microsoft.com/en-
                  us/resources/policies/personalized-ads"
                >
                  Bing Ads Link.
                </a>{" "}
                <br />
                <br />
                You can learn more about the privacy practices and policies of
                Microsoft by visiting their Privacy Policy page:{" "}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://privacy.microsoft.com/en-
                  us/PrivacyStatement"
                >
                  Microsoft Privacy Link.
                </a>{" "}
                <br />
                <br />
                * Twitter <br />
                <br /> Twitter remarketing service is provided by Twitter Inc.{" "}
                <br />
                <br />
                You can opt-out from Twitter's interest-based ads by following
                their instructions:{" "}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://support.twitter.com/articles/20170405"
                >
                  Twitter Ads Link.
                </a>{" "}
                <br /> <br />
                You can learn more about the privacy practices and policies of
                Twitter by visiting their Privacy Policy page:{" "}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://twitter.com/privacy"
                >
                  https://twitter.com/privacy
                </a>{" "}
                <br />
                <br />
                * Facebook <br />
                <br /> Facebook remarketing service is provided by Facebook Inc.{" "}
                <br />
                <br /> You can learn more about interest-based advertising from
                Facebook by visiting this page:{" "}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.facebook.com/help/164968693837950"
                >
                  Link.
                </a>{" "}
                <br />
                <br />
                To opt-out from Facebook's interest-based ads, follow these
                instructions from Facebook:{" "}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.facebook.com/help/568137493302217"
                >
                  Link.
                </a>{" "}
                <br />
                <br />
                Facebook adheres to the Self-Regulatory Principles for Online
                Behavioural Advertising established by the Digital Advertising
                Alliance. You can also opt-out from Facebook and other
                participating companies through the Digital Advertising Alliance
                in the USA
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="http://www.aboutads.info/choices/"
                >
                  http://www.aboutads.info/choices/
                </a>
                , the Digital Advertising Alliance of Canada in Canada{" "}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="http://youradchoices.ca/"
                >
                  http://youradchoices.ca/
                </a>{" "}
                or the European Interactive Digital Advertising Alliance in
                Europe{" "}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="http://www.youronlinechoices.eu/"
                >
                  http://www.youronlinechoices.eu/
                </a>
                , or opt-out using your mobile device settings. <br />
                <br /> For more information on the privacy practices of
                Facebook, please visit Facebook's Data Policy:{" "}
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.facebook.com/privacy/explanation"
                >
                  Link
                </a>{" "}
                <br />
                <br />
                * Perfect Audience <br />
                <br /> Perfect Audience remarketing service is provided by
                NowSpots Inc. You can opt-out of Perfect Audience remarketing by
                visiting these pages: Platform Opt-out (
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="http://pixel.prfct.co/coo"
                >
                  http://pixel.prfct.co/coo
                </a>
                ) and Partner Opt-out (
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="http://ib.adnxs.com/optout"
                >
                  http://ib.adnxs.com/optout
                </a>
                ). <br />
                <br /> For more information on the privacy practices of Perfect
                Audience, please visit the Perfect Audience Privacy Policy &
                Opt-out web page: [
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.perfectaudience.com/privacy/"
                >
                  Link 1.
                </a>
                ](
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.perfectaudience.com/privacy/index.html"
                >
                  Link 2.
                </a>
                )
              </p>
              <div className="d-flex justify-content-left">
                <h6 className="sans">Usage, Performance and Miscellaneous</h6>
              </div>
              <p>
                We may use third-party Service Providers to provide better
                improvement of our Service. <br />
                <br /> * Google Places <br />
                <br /> Google Places is a service that returns information about
                places using HTTP requests. It is operated by Google. <br />
                <br />
                Google Places service may collect information from You and from
                Your Device for security purposes. <br />
                <br /> The information gathered by Google Places is held in
                accordance with the Privacy Policy of Google:
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.google.com/intl/en/policies/privacy"
                >
                  Google Privacy Link.
                </a>
              </p>
              <div className="d-flex justify-content-left">
                <h3 className="sans">CCPA Privacy</h3>
              </div>
              <div className="d-flex justify-content-left">
                <h6 className="sans">Your Rights under the CCPA</h6>
              </div>
              <p>
                Under this Privacy Policy, and by law if You are a resident of
                California, You have the following rights: <br />
                <br /> * The right to notice. You must be properly notified
                which categories of Personal Data are being collected and the
                purposes for which the Personal Data is being used. <br />{" "}
                <br /> * The right to access / the right to request. The CCPA
                permits You to request and obtain from the Company information
                regarding the disclosure of Your Personal Data that has been
                collected in the past 12 months by the Company or its
                subsidiaries to a third-party for the third party's direct
                marketing purposes. <br />
                <br />* The right to say no to the sale of Personal Data. You
                also have the right to ask the Company not to sell Your Personal
                Data to third parties. You can submit such a request by visiting
                our "Do Not Sell My Personal Information" section or web page.{" "}
                <br />
                <br /> * The right to know about Your Personal Data. You have
                the right to request and obtain from the Company information
                regarding the disclosure of the following: <br /> * The
                categories of Personal Data collected <br /> * The sources from
                which the Personal Data was collected <br />
                * The business or commercial purpose for collecting or selling
                the Personal Data <br /> * Categories of third parties with whom
                We share Personal Data <br /> * The specific pieces of Personal
                Data we collected about You <br /> <br /> * The right to delete
                Personal Data. You also have the right to request the deletion
                of Your Personal Data that have been collected in the past 12
                months. <br />
                <br /> * The right not to be discriminated against. You have the
                right not to be discriminated against for exercising any of Your
                Consumer's rights, including by: <br /> * Denying goods or
                services to You <br /> * Charging different prices or rates for
                goods or services, including the use of discounts or other
                benefits or imposing penalties <br />
                * Providing a different level or quality of goods or services to
                You <br /> * Suggesting that You will receive a different price
                or rate for goods or services or a different level or quality of
                goods or services.
              </p>
              <div className="d-flex justify-content-left">
                <h6 className="sans">
                  Exercising Your CCPA Data Protection Rights
                </h6>
              </div>
              <p>
                In order to exercise any of Your rights under the CCPA, and if
                you are a California resident, You can email or call us or visit
                our "Do Not Sell My Personal Information" section or web page.{" "}
                <br />
                <br /> The Company will disclose and deliver the required
                information free of charge within 45 days of receiving Your
                verifiable request. The time period to provide the required
                information may be extended once by an additional 45 days when
                reasonable necessary and with prior notice.
              </p>
              <div className="d-flex justify-content-left">
                <h6 className="sans">Do Not Sell My Personal Information</h6>
              </div>
              <p>
                We do not sell personal information. However, the Service
                Providers we partner with (for example, our advertising
                partners) may use technology on the Service that "sells"
                personal information as defined by the CCPA law. <br />
                <br /> If you wish to opt out of the use of your personal
                information for interest- based advertising purposes and these
                potential sales as defined under CCPA law, you may do so by
                following the instructions below. <br />
                <br /> Please note that any opt out is specific to the browser
                You use. You may need to opt out on every browser that you use.
              </p>
              <div className="d-flex justify-content-left">
                <h6 className="sans">Website</h6>
              </div>
              <p>
                You can opt out of receiving ads that are personalized as served
                by our Service Providers by following our instructions presented
                on the Service: <br />
                <br /> * From Our "Cookie Consent" notice banner <br />* Or from
                Our "CCPA Opt-out" notice banner <br />* Or from Our "Do Not
                Sell My Personal Information" notice banner <br />* Or from Our
                "Do Not Sell My Personal Information" link <br />
                <br /> The opt out will place a cookie on Your computer that is
                unique to the browser You use to opt out. If you change browsers
                or delete the cookies saved by your browser, you will need to
                opt out again.
              </p>
              <div className="d-flex justify-content-left">
                <h6 className="sans">Mobile Devices</h6>
              </div>
              <p>
                Your mobile device may give you the ability to opt out of the
                use of information about the apps you use in order to serve you
                ads that are targeted to your interests: <br />
                <br /> * "Opt out of Interest-Based Ads" or "Opt out of Ads
                Personalization" on Android devices * <br /> "Limit Ad Tracking"
                on iOS devices <br />
                <br /> You can also stop the collection of location information
                from Your mobile device by changing the preferences on your
                mobile device.
              </p>
              <div className="d-flex justify-content-left">
                <h3 className="sans">
                  "Do Not Track" Policy as Required by California Online Privacy
                  Protection Act (CalOPPA)
                </h3>
              </div>
              <p>
                Our Service does not respond to Do Not Track signals. <br />
                <br /> However, some third party websites do keep track of Your
                browsing activities. If You are visiting such websites, You can
                set Your preferences in Your web browser to inform websites that
                You do not want to be tracked. You can enable or disable DNT by
                visiting the preferences or settings page of Your web browser.
              </p>
              <div className="d-flex justify-content-left">
                <h3 className="sans">
                  Your California Privacy Rights (California's Shine the Light
                  law)
                </h3>
              </div>
              <p>
                Under California Civil Code Section 1798 (California's Shine the
                Light law), California residents with an established business
                relationship with us can request information once a year about
                sharing their Personal Data with third parties for the third
                parties' direct marketing purposes. <br />
                <br /> If you'd like to request more information under the
                California Shine the Light law, and if you are a California
                resident, You can contact Us using the contact information
                provided below.
              </p>
              <div className="d-flex justify-content-left">
                <h3 className="sans">
                  California Privacy Rights for Minor Users (California Business
                  and Professions Code Section 22581)
                </h3>
              </div>
              <p>
                California Business and Professions Code section 22581 allow
                California residents under the age of 18 who are registered
                users of online sites, services or applications to request and
                obtain removal of content or information they have publicly
                posted. <br />
                <br /> To request removal of such data, and if you are a
                California resident, You can contact Us using the contact
                information provided below, and include the email address
                associated with Your account. <br />
                <br /> Be aware that Your request does not guarantee complete or
                comprehensive removal of content or information posted online
                and that the law may not permit or require removal in certain
                circumstances.
              </p>
              <div className="d-flex justify-content-left">
                <h3 className="sans">Links to Other Websites</h3>
              </div>
              <p>
                Our Service may contain links to other websites that are not
                operated by Us. If You click on a third party link, You will be
                directed to that third party's site. We strongly advise You to
                review the Privacy Policy of every site You visit. <br />
                <br /> We have no control over and assume no responsibility for
                the content, privacy policies or practices of any third party
                sites or services.
              </p>
              <div className="d-flex justify-content-left">
                <h3 className="sans">Changes to this Privacy Policy</h3>
              </div>
              <p>
                We may update our Privacy Policy from time to time. We will
                notify You of any changes by posting the new Privacy Policy on
                this page. <br />
                <br /> We will let You know via email and/or a prominent notice
                on Our Service, prior to the change becoming effective and
                update the "Last updated" date at the top of this Privacy
                Policy. <br />
                <br /> You are advised to review this Privacy Policy
                periodically for any changes. Changes to this Privacy Policy are
                effective when they are posted on this page.
              </p>
              <div className="d-flex justify-content-left">
                <h3 className="sans">Contact Us</h3>
              </div>
              <p>
                If you have any questions about this Privacy Policy, You can
                contact us: <br /> <br /> * By email:{" "}
                <a
                  className="con-hover"
                  href={`mailto:${data.site.siteMetadata.email}`}
                >
                  {data.site.siteMetadata.email}
                </a>{" "}
                <br />
                <br /> * By visiting this page on our website:{" "}
                <Link to="/contact">{data.site.siteMetadata.contactUrl}</Link>
                <br />
                <br /> * By phone number:{" "}
                <a
                  className="con-hover"
                  href={`tel:${data.site.siteMetadata.tel}`}
                >
                  {data.site.siteMetadata.tel}
                </a>
                <br />
                <br /> * By mail: &nbsp;{data.site.siteMetadata.address}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default PrivacyPage

export const query = graphql`
  {
    site {
      siteMetadata {
        name
        tel
        email
        address
        siteUrl
        date
        domain
        contactUrl
      }
    }
  }
`
